import { gql } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import {
  CollectionRole,
  GroupId,
  OrgRole,
  UserId,
  groupByDiscriminate,
  humanReadableCollectionRole,
} from "@hex/common";
import { sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { HexMenuDivider, HexMenuItem } from "../../../../hex-components";
import { ORG_ID } from "../../../../orgs";
import CollectionsGateIcon from "../../../../static/illustration/illustration-featuregate-collections.inline.svg";
import { useHexFlag } from "../../../../util/useHexFlags.js";
import { useFeatureGates } from "../../../feature-gate/FeatureGateContext";
import {
  FeatureGateDisabledOverlay,
  FeatureGateZeroState,
} from "../../../feature-gate/FeatureGateZeroState";
import { GroupsIcon } from "../../../icons/CustomIcons";
import { PotentialProjectPermissionGrant } from "../../../share/AddPermissionsBarNew";
import { AddPermissionsBarNew } from "../../../share/AddPermissionsBarNew.js";
import { CollectionRoleDropdown } from "../../../share/CollectionRoleDropdown";
import { OrgOrPublicAvatar } from "../../../share/OrgOrPublicAvatar.js";
import { PermissionRow, PermissionRowItem } from "../../../share/PermissionRow";
import { WiderSharingOption } from "../../../share/WiderSharingOption";
import { Avatar } from "../../../user/Avatar";
import { UserAvatar } from "../../../user/UserAvatar";

import { useGetOrgForCollectionMembershipQuery } from "./CollectionMembership.generated";

gql`
  query GetOrgForCollectionMembership($orgId: OrgId!) {
    orgById(orgId: $orgId) {
      id
      displayName
      hasCustomAppIcon
      allowWorkspaceSharing
      userCount(
        activeFilter: ONLY_ACTIVE
        orgRoleFilter: [ADMIN, MANAGER, EDITOR, EXPLORER, MEMBER]
      )
      sharedCollectionCount
    }
  }
`;

const MembershipContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100%;
`;

const MembershipHeader = styled.div`
  padding-bottom: 15px;
`;

const MembershipList = styled.div<{
  $isScrollable?: boolean;
  $isFixedHeight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${({ $isFixedHeight }) => $isFixedHeight && `height: 220px;`}

  ${({ $isScrollable }) =>
    $isScrollable &&
    `
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
      /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
      &:vertical {
        border-left: none;
      }
    }
  `}
`;

export interface MemberUser {
  id: UserId;
  email: string;
  role: CollectionRole;
  name?: string | null;
  imageUrl?: string | null;
  orgRole: OrgRole;
}

export interface MemberGroup {
  id: GroupId;
  name: string;
  role: CollectionRole;
}

export interface SaveUsersAndGroupsCallbackProps {
  users: MemberUser[];
  groups: MemberGroup[];
  orgCollectionRole: CollectionRole | undefined;
}

// These props are used by callers that want to immediately save changes via mutation
interface ImmediateSaveProps {
  saveCollectionPermissions: (
    args: SaveUsersAndGroupsCallbackProps,
  ) => Promise<void>;
  setSelectedUsers?: undefined;
  setSelectedGroups?: undefined;
  setOrgCollectionRole?: undefined;
}

// These props are used for a 2-step save process, allowing the caller to have an independent save changes flow.
interface TwoStepSaveProps {
  saveCollectionPermissions?: undefined;
  setSelectedUsers: (users: MemberUser[]) => void;
  setSelectedGroups: (groups: MemberGroup[]) => void;
  setOrgCollectionRole: (collectionRole: CollectionRole | undefined) => void;
}

interface CollectionMembershipBaseProps {
  canEdit: boolean;
  isScrollable?: boolean;
  selectedUsers: MemberUser[];
  selectedGroups: MemberGroup[];
  orgCollectionRole?: CollectionRole;
  setActiveTagsInSearch?: (hasActiveTags: boolean) => void;
  /**
   * Whether or not the collection is shared according to the database as ground truth.
   * This ensures that when we have a feature gate collection limit, we do not accidentally
   * expose the feature gate limits until the collection has been saved.
   */
  persistedIsSharedCollection: boolean;
}

type CollectionMembershipProps = CollectionMembershipBaseProps &
  (TwoStepSaveProps | ImmediateSaveProps);

export const CollectionMembership: React.ComponentType<CollectionMembershipProps> =
  React.memo(function CollectionMembership({
    canEdit,
    isScrollable,
    orgCollectionRole,
    persistedIsSharedCollection,
    saveCollectionPermissions,
    selectedGroups,
    selectedUsers,
    setActiveTagsInSearch,
    setOrgCollectionRole,
    setSelectedGroups,
    setSelectedUsers,
  }: CollectionMembershipProps) {
    const orgQueryData = useGetOrgForCollectionMembershipQuery({
      variables: {
        orgId: ORG_ID,
      },
    });
    const orgData = orgQueryData.data?.orgById;
    const orgId = orgData?.id;
    const orgName = orgData?.displayName ?? "Your Organization";
    const allowWorkspaceSharing = orgData?.allowWorkspaceSharing ?? false;
    const orgMemberCount = orgData?.userCount ?? 0;
    const hasCustomAppIcon =
      orgQueryData.data?.orgById.hasCustomAppIcon ?? false;
    const orgSharedCollectionCount = orgData?.sharedCollectionCount ?? 0;
    const explorerRoleCanViewChange = useHexFlag(
      "explorer-role-can-view-change",
    );

    const { sharedCollectionLimit } = useFeatureGates();

    const sharingDisabledFeatureGate =
      !persistedIsSharedCollection &&
      orgSharedCollectionCount != null &&
      sharedCollectionLimit != null &&
      sharedCollectionLimit <= orgSharedCollectionCount;

    const [potentialRole, setPotentialRole] = React.useState<CollectionRole>(
      CollectionRole.MEMBER,
    );
    const [potentialUsersAndGroups, setPotentialUsersAndGroups] = useState<
      PotentialProjectPermissionGrant[]
    >([]);

    const existingEmails = useMemo(
      () => new Set(selectedUsers.map((u) => u.email)),
      [selectedUsers],
    );
    const existingGroupIds = useMemo(
      () => new Set(selectedGroups.map((g) => g.id)),
      [selectedGroups],
    );

    const saveUsersAndGroupsCallback = useCallback(
      async ({
        groups = selectedGroups,
        users = selectedUsers,
      }: {
        users?: MemberUser[];
        groups?: MemberGroup[];
      }) => {
        await saveCollectionPermissions?.({
          users,
          groups,
          orgCollectionRole,
        });
        setSelectedUsers?.(users);
        setSelectedGroups?.(groups);
      },
      [
        orgCollectionRole,
        saveCollectionPermissions,
        selectedGroups,
        selectedUsers,
        setSelectedGroups,
        setSelectedUsers,
      ],
    );

    const addUsersAndGroups = useCallback(
      async ({
        projGrants,
        role,
      }: {
        role: CollectionRole;
        projGrants: PotentialProjectPermissionGrant[];
      }) => {
        const groupedMembers = groupByDiscriminate(projGrants, "type");
        const potentialUsers = groupedMembers["user"] ?? [];
        const potentialUsersWithRoles = potentialUsers
          .map((u) =>
            u.id != null && u.orgRole != null
              ? { role, ...u, orgRole: u.orgRole, id: u.id }
              : undefined,
          )
          .filter((obj) => obj != null);

        const potentialGroups = groupedMembers["group"] ?? [];
        const potentialGroupsWithRoles = potentialGroups.map((g) => ({
          role,
          ...g,
          id: g.id,
        }));

        await saveUsersAndGroupsCallback({
          users: [...selectedUsers, ...potentialUsersWithRoles],
          groups: [...selectedGroups, ...potentialGroupsWithRoles],
        });

        setPotentialUsersAndGroups([]);
      },
      [saveUsersAndGroupsCallback, selectedGroups, selectedUsers],
    );

    const updateGroupRole = useCallback(
      async (groupId: GroupId, newRole: CollectionRole) => {
        const group = selectedGroups.find((g) => g.id === groupId);
        if (group != null) {
          await saveUsersAndGroupsCallback({
            groups: selectedGroups
              .filter((g) => g.id !== groupId)
              .concat({ ...group, role: newRole }),
          });
        }
      },
      [selectedGroups, saveUsersAndGroupsCallback],
    );

    const removeGroup = useCallback(
      async (groupId: GroupId) => {
        await saveUsersAndGroupsCallback({
          groups: selectedGroups.filter((g) => g.id !== groupId),
        });
      },
      [selectedGroups, saveUsersAndGroupsCallback],
    );

    const updateUserRole = useCallback(
      async (userId: UserId, newRole: CollectionRole) => {
        const user = selectedUsers.find((u) => u.id === userId);
        if (user != null) {
          await saveUsersAndGroupsCallback({
            users: selectedUsers
              .filter((u) => u.id !== userId)
              .concat({ ...user, role: newRole }),
          });
        }
      },
      [selectedUsers, saveUsersAndGroupsCallback],
    );

    const removeUser = useCallback(
      async (userId: UserId) => {
        await saveUsersAndGroupsCallback({
          users: selectedUsers.filter((u) => u.id !== userId),
        });
      },
      [saveUsersAndGroupsCallback, selectedUsers],
    );

    const orderedGroups = useMemo(
      () => sortBy(selectedGroups, (g) => g.name.toLowerCase()),
      [selectedGroups],
    );

    const orderedUsers = useMemo(
      () => sortBy(selectedUsers, (u) => u.email.toLowerCase()),
      [selectedUsers],
    );

    const setOrgCollectionRoleCallback = useCallback(
      async (role: CollectionRole | undefined) => {
        setOrgCollectionRole?.(role);
        await saveCollectionPermissions?.({
          users: selectedUsers,
          groups: selectedGroups,
          orgCollectionRole: role,
        });
      },
      [
        saveCollectionPermissions,
        selectedGroups,
        selectedUsers,
        setOrgCollectionRole,
      ],
    );

    const setShareWithWorkspaceCallback = useCallback(
      async (isShared: boolean) => {
        if (!isShared) {
          await setOrgCollectionRoleCallback(undefined);
        } else {
          await setOrgCollectionRoleCallback(CollectionRole.MEMBER);
        }
      },
      [setOrgCollectionRoleCallback],
    );

    useEffect(() => {
      setActiveTagsInSearch?.(potentialUsersAndGroups.length > 0);
    }, [potentialUsersAndGroups, setActiveTagsInSearch]);

    if (!canEdit) {
      return (
        <MembershipContainer>
          <MembershipList $isScrollable={isScrollable}>
            {orgCollectionRole != null && (
              <div>
                <PermissionRowItem
                  avatar={
                    <OrgOrPublicAvatar
                      css={{ marginRight: 0 }}
                      hasCustomAppIcon={hasCustomAppIcon}
                      orgId={orgId ?? ORG_ID}
                      orgName={orgName}
                    />
                  }
                  label={`${orgMemberCount} users (${humanReadableCollectionRole(
                    orgCollectionRole,
                  )})`}
                  name={orgName}
                />
              </div>
            )}
            {orderedGroups.map((group, i) => (
              <div key={`group-${i}`}>
                <PermissionRowItem
                  avatar={
                    <Avatar
                      active={true}
                      altText={group.name}
                      size={30}
                      text={<GroupsIcon />}
                    />
                  }
                  label=""
                  name={group.name}
                />
              </div>
            ))}
            {orderedUsers.map((user, i) => (
              <div key={`user-${i}`}>
                <PermissionRowItem
                  avatar={
                    <UserAvatar
                      active={true}
                      email={user.email}
                      imageUrl={user.imageUrl ?? undefined}
                      name={user.name ?? undefined}
                      size={30}
                    />
                  }
                  label={humanReadableCollectionRole(user.role)}
                  name={user.name || user.email}
                />
              </div>
            ))}
          </MembershipList>
        </MembershipContainer>
      );
    }

    const shareWithOrgRolePicker = orgCollectionRole ? (
      <CollectionRoleDropdown
        selectedRole={orgCollectionRole}
        onSelectRole={setOrgCollectionRoleCallback}
      ></CollectionRoleDropdown>
    ) : undefined;

    return (
      <MembershipContainer>
        {sharingDisabledFeatureGate && (
          <FeatureGateZeroState
            css={`
              height: auto;
              position: absolute;
            `}
            description="Upgrade to share collections."
            featureGate="sharedCollectionLimit"
            icon={<CollectionsGateIcon />}
            title={`Your workspace has reached its limit of ${sharedCollectionLimit} shared collections.`}
          />
        )}
        <FeatureGateDisabledOverlay $disabled={sharingDisabledFeatureGate}>
          <MembershipHeader>
            <AddPermissionsBarNew<CollectionRole>
              addPotentialProjectGrants={addUsersAndGroups}
              allowUserCreation={false}
              disabled={sharingDisabledFeatureGate}
              existingEmails={existingEmails}
              existingGroupIds={existingGroupIds}
              potentialProjectGrants={potentialUsersAndGroups}
              potentialRole={potentialRole}
              rolePicker={
                <CollectionRoleDropdown
                  selectedRole={potentialRole}
                  onSelectRole={setPotentialRole}
                />
              }
              setPotentialProjectGrants={setPotentialUsersAndGroups}
            />
            <HexMenuDivider />
          </MembershipHeader>
          <MembershipList
            $isFixedHeight={sharingDisabledFeatureGate}
            $isScrollable={isScrollable}
          >
            {allowWorkspaceSharing && (
              <WiderSharingOption
                $subtle={true}
                disabled={sharingDisabledFeatureGate}
                enabled={orgCollectionRole !== undefined}
                icon={
                  <OrgOrPublicAvatar
                    hasCustomAppIcon={hasCustomAppIcon}
                    orgId={orgId ?? ORG_ID}
                    orgName={orgName}
                  />
                }
                label={`${orgMemberCount} users`}
                rolePicker={shareWithOrgRolePicker}
                selectedRole={orgCollectionRole}
                title={`Share with ${orgName}`}
                onEnabledToggle={setShareWithWorkspaceCallback}
              />
            )}
            {orderedGroups.map((g) => {
              return (
                <PermissionRow
                  key={g.id}
                  data={{ ...g, dataType: "group" }}
                  explorerRoleCanViewChange={explorerRoleCanViewChange}
                  rolePicker={
                    <CollectionRoleDropdown
                      additionalActions={
                        <>
                          <HexMenuDivider />
                          <HexMenuItem
                            intent={Intent.DANGER}
                            text="Remove group"
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => removeGroup(g.id)}
                          />
                        </>
                      }
                      selectedRole={g.role}
                      // eslint-disable-next-line react/jsx-no-bind
                      onSelectRole={(newRole) => updateGroupRole(g.id, newRole)}
                    />
                  }
                />
              );
            })}
            {orderedUsers.map((u) => {
              return (
                <PermissionRow
                  key={u.id}
                  data={{
                    ...u,
                    imageUrl: u.imageUrl ?? undefined,
                    dataType: "user",
                  }}
                  explorerRoleCanViewChange={explorerRoleCanViewChange}
                  rolePicker={
                    <CollectionRoleDropdown
                      additionalActions={
                        <>
                          <HexMenuDivider />
                          <HexMenuItem
                            intent={Intent.DANGER}
                            text="Remove user"
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => removeUser(u.id)}
                          />
                        </>
                      }
                      selectedRole={u.role}
                      // eslint-disable-next-line react/jsx-no-bind
                      onSelectRole={(newRole) => updateUserRole(u.id, newRole)}
                    />
                  }
                />
              );
            })}
          </MembershipList>
        </FeatureGateDisabledOverlay>
      </MembershipContainer>
    );
  });
